import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

const scroll = keyframes`
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-50%, 0, 0); }
`;

const MarqueeWrapper = styled.div`
  height: 5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

const MarqueeContent = styled.div`
  display: flex;
  position: absolute;
  will-change: transform;
  animation: ${scroll} 60s linear infinite;
  gap: 5rem;

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 10rem;
  }
`;

const Marquee = ({ children }) => {
  const items = React.Children.toArray(children);
  const duplicatedItems = items.map((child, index) =>
    React.cloneElement(child, { key: `${child.key || index}-dup` })
  );

  return (
    <MarqueeWrapper>
      <MarqueeContent>{items.concat(duplicatedItems)}</MarqueeContent>
    </MarqueeWrapper>
  );
};

Marquee.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Marquee;
