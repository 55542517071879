import styled from '@emotion/styled';
import HeadlineSection from 'molecules/HeadlineSection';
import TableSection from 'organisms/TableSection';
import coolingConfig from 'data/cooling';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(4rem, 5vw, 8rem);
`;

const FutureProofSection = () => {
  const {
    sectionTitle,
    sectionSubtitle,
    headerLeft,
    headerCenter,
    headerRight,
    headerFarRight,
    tableItems,
    headLeftBgColor,
    headCenterBgColor,
    headRightBgColor,
    headFarRightBgColor,
    cellTextColor,
    columnLeftBgColor,
    columnCenterBgColor,
    columnRightBgColor,
    columnFarRightBgColor,
  } = coolingConfig.futureProofSection;
  return (
    <Container>
      <Content>
        <HeadlineSection
          titleWidth={90}
          subtitleWidth={50}
          sectionTitle={sectionTitle}
          sectionSubtitle={sectionSubtitle}
        />
        <TableSection
          headerLeft={headerLeft}
          headerCenter={headerCenter}
          headerRight={headerRight}
          headerFarRight={headerFarRight}
          tableItems={tableItems}
          headLeftBgColor={headLeftBgColor}
          headCenterBgColor={headCenterBgColor}
          headRightBgColor={headRightBgColor}
          headFarRightBgColor={headFarRightBgColor}
          cellTextColor={cellTextColor}
          columnLeftBgColor={columnLeftBgColor}
          columnCenterBgColor={columnCenterBgColor}
          columnRightBgColor={columnRightBgColor}
          columnFarRightBgColor={columnFarRightBgColor}
        />
      </Content>
    </Container>
  );
};
export default FutureProofSection;
