// layouts/DataCenterLayout.jsx
import PropTypes from 'prop-types';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';

const DataCenterLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

DataCenterLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataCenterLayout;
