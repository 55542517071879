const content = {
  seo: {
    title: 'TigerDC | Approach',
    description:
      'TigerDC provides end-to-end solutions, from site selection and acquisition to design, construction, and operations. Our services are backed by experienced real estate leadership, skilled in-house construction management, and top-tier technical expertise from our design teams and contractor partners.',
    canonical: '/approach',
  },
  headlineSection: {
    sectionTitle: 'Our Approach',
    sectionSubtitle:
      'TigerDC provides end-to-end solutions, from site selection and acquisition to design, construction, and operations. Our services are backed by experienced real estate leadership, skilled in-house construction management, and top-tier technical expertise from our design teams and contractor partners.',
  },
  stageCard: {
    stageCards: [
      {
        primaryTextColor: 'white',
        secondaryTextColor: 'skyBlue',
        cardBgColor: 'white',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/site-inspection.svg',
        iconLabel: 'site selection',
        iconBgColor: 'silver',
        iconShrink: 2.2,
        stageNumber: '01',
        stageTitle: 'Site Selection & Evaluation',
        featureTexts: [
          {
            featureTitle: '30+ Years of Expertise:',
            featureDescription:
              'Precision in identifying and evaluating prime real estate.',
          },
          {
            featureTitle: 'Informed Decisions:',
            featureDescription:
              'Strong municipal and utility connections guide impactful site choices.',
          },
          {
            featureTitle: 'Holistic Development:',
            featureDescription:
              'Assessments of power, fiber, water, sewer, and stormwater.',
          },
        ],
      },
      {
        primaryTextColor: 'white',
        secondaryTextColor: 'darkBlue',
        cardBgColor: 'white',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/property-acquisition.svg',
        iconLabel: 'property acquisition',
        iconBgColor: 'silver',
        iconShrink: 2.2,
        stageNumber: '02',
        stageTitle: 'Property Acquisition',
        featureTexts: [
          {
            featureTitle: 'Guaranteed Results:',
            featureDescription: 'Acquire and entitle sites with certainty.',
          },
          {
            featureTitle: 'Proven Success:',
            featureDescription:
              'Expertise in municipal processes like rezoning and site approvals.',
          },
          {
            featureTitle: 'Power Solutions:',
            featureDescription:
              'On-site substations to shorten power delivery timelines.',
          },
        ],
      },
      {
        primaryTextColor: 'darkBlue',
        secondaryTextColor: 'charcoal',
        cardBgColor: 'white',
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/design-construction.svg',
        iconLabel: 'design and construction',
        iconBgColor: 'silver',
        iconShrink: 2.1,
        stageNumber: '03',
        stageTitle: 'Shell Design & Construction',
        featureTexts: [
          {
            featureTitle: 'Expert Team:',
            featureDescription: '20+ years in core and shell construction.',
          },
          {
            featureTitle: 'Flexible & Adaptive:',
            featureDescription: 'Designs ready to pivot for any BTS strategy.',
          },
          {
            featureTitle: 'Advanced Cooling:',
            featureDescription: 'Flexible with multiple cooling solutions.',
          },
        ],
      },
    ],
  },
};

export default content;
