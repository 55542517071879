import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import Icon from 'atoms/Icon';
import { GlobalContext } from 'context/GlobalContext';

const ScrollToTopButton = styled.button`
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 25px;
  right: 25px;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.scrollToTop.background};
  color: ${({ theme }) => theme.colors.scrollToTop.icon};
  box-shadow: 0 1px 2px ${({ theme }) => theme.colors.scrollToTop.shadow};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transform: ${({ isVisible }) => (isVisible ? 'scale(1)' : 'scale(0.5)')};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.scrollToTop.backgroundHover};

      .icon-wrapper {
        transform: scale(1.5);
      }
    }
  }
`;

const IconWrapper = styled.div`
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { menuOpen } = useContext(GlobalContext);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 20;
      if (window.scrollY > scrollThreshold && !menuOpen) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ScrollToTopButton
      isVisible={isVisible}
      aria-label="Scroll to top"
      onClick={scrollToTop}
    >
      <IconWrapper className="icon-wrapper" isVisible={isVisible}>
        <Icon iconName="arrowUp" size="2x" iconType="solid" />
      </IconWrapper>
    </ScrollToTopButton>
  );
};

export default ScrollToTop;
