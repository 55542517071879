import styled from '@emotion/styled';
import Features from 'molecules/Features';
import Button from 'atoms/Button';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  position: relative;
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  text-align: center;
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(3rem, 4vw, 4rem);
`;

const Meta = styled.div`
  gap: clamp(0.4rem, 0.6vw, 1rem);
  ${({ theme }) => theme.mixins.flexColCenter};
  color: ${({ theme }) => theme.colors.white};
`;

const Title = styled.h2`
  ${({ theme }) => theme.mixins.textH2};
  text-transform: uppercase;
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textSubtitle};
  width: clamp(22rem, 60%, 40rem);
`;

const Bg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const BgOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => `${theme.colors.darkBlue}A6`},
    ${({ theme }) => `${theme.colors.darkBlue}D9`}
  );
`;

const HomeFeatured = ({ content }) => {
  const { title, subtitle, features, buttonUrl, buttonLabel, bgImage } =
    content;

  return (
    <Container id="featured">
      <Content>
        <Meta>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Meta>
        <Features features={features} />
        <Button
          url={buttonUrl}
          label={buttonLabel}
          color="greenAccent"
          hoverColor="white"
        />
      </Content>
      <Bg bgImage={bgImage} aria-hidden="true">
        <BgOverlay aria-hidden="true"></BgOverlay>
      </Bg>
    </Container>
  );
};

export default HomeFeatured;
