const content = {
  seo: {
    title: 'TigerDC | Data Centers',
    description:
      'AI Data Centers are the future with high density configurations, advanced GPU infrastructure, liquid cooling, high-speed networking, and unmatched scalability.',
    canonical: '/datacenters',
  },
  identifiers: ['sc1'],
  sc1: {
    overview: {
      title: 'SC1 AIDC',
      subtitle:
        'A 100+ MW project in South Carolina is underway, targeting 2026 completion, with 600 MW capacity secured in the AIDC pipeline.',
    },
    images: {
      hero: 'https://storage.googleapis.com/tiger-dc-web/assets/images/BuildingEntrance/xl.webp',
      slider: [
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/assets/images/BuildingEntrance/l.webp',
          title: 'Building Entrance',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/assets/images/SiteEntrance/l.webp',
          title: 'Site Entrance',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/assets/images/AerialView/l.webp',
          title: 'Aerial View',
        },
      ],
    },
    highlights: [
      {
        id: 1,
        title: 'Power',
        items: ['50 MW approved now', 'Additional 60 MW ready by 2025'],
      },
      {
        id: 2,
        title: 'Facility',
        items: ['Existing 100 KV / 34.5 KV substation'],
      },
      {
        id: 3,
        title: 'Land',
        items: ['44 acres lease to purchase'],
      },
    ],
    specs: {
      power: {},
      network: {},
      land: {},
    },
    siteDetails: {
      address: '1540 Broadway Ste 1010',
      city: 'Charlotte',
      state: 'NC',
      zip: '28205',
      geo: {
        lat: 34.863082,
        lng: -82.01883,
      },
    },
    about: {
      // welcomeSection: {
      //   sectionTitles: ['WELCOME', 'TO', 'SC1'],
      //   sectionSubtitle:
      //     'Learn about the specs and capabilities of our newest data center located in South Carolina.',
      //   image: {
      //     src: 'https://storage.googleapis.com/tiger-dc-web/images/MainEntrance/l.webp',
      //     alt: 'Interior photograph of SC1 AI data center',
      //     fit: 'cover',
      //   },
      // },
      contentTop: {
        topHeadline:
          'Welcome to the Future of Computing: Our AI-Focused Data Center',
        topCaption:
          'Unlock the full potential of artificial intelligence with our state-of-the-art AI Data Center, designed specifically to meet the demanding needs of modern machine learning and AI workloads. Our facilities are at the cutting edge of technology, combining high-performance infrastructure, optimized processing power, and unmatched reliability.',
      },
      banner: {
        bannerSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/BuildingEntrance/xxl.webp',
        bannerAltLabel: 'TigerDC data center entrance image',
      },
      infoGrid: {
        gridTitle: 'Features',
        gridItems: [
          {
            itemTitle: 'AI-Optimized Hardware',
            itemDescription:
              'Our data center is equipped with the latest GPUs, TPUs, and other AI-accelerated hardware, ensuring that your AI models are trained faster and more efficiently. Each server is purpose-built for AI workloads, providing seamless scalability and performance at every stage of your project.',
          },
          {
            itemTitle: 'Scalability on Demand',
            itemDescription:
              'Whether you need computing power for small tasks or to scale up for massive datasets, our infrastructure is built to grow with you. Scale your operations effortlessly with dynamic provisioning and cutting-edge automation.',
          },
          {
            itemTitle: 'High-Performance Storage',
            itemDescription:
              'Our AI Data Center provides fast, reliable, and cost-effective storage solutions that handle the massive datasets AI requires. With NVMe storage and hybrid options, we ensure your data is always accessible when you need it most.',
          },
          {
            itemTitle: 'Green Energy Commitment',
            itemDescription:
              'Sustainability is at our core. Our facilities are powered by renewable energy sources, reducing your carbon footprint while maintaining top-tier performance.',
          },
        ],
      },
      iconStrip: {
        iconStripTitle: 'Utilities',
        iconStripBgColor: 'lightBlue',
        iconStripTitleColor: 'white',
        iconTitleColor: 'white',
        iconDescriptionColor: 'darkBlue',
        iconStripIcons: [
          {
            iconSrc:
              'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/fiber-utility.svg',
            iconLabel: 'white optics utility',
            iconBgColor: 'skyBlue',
            iconShrink: 2.3,
            title: 'FIBER',
            description: 'Zayo, Segra and Lumen with up to 400 Gbps',
          },
          {
            iconSrc:
              'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/water-utility.svg',
            iconLabel: 'water utility',
            iconBgColor: 'skyBlue',
            iconShrink: 2,
            title: 'WATER',
            description: '12” Utility \n Water Line',
          },
          {
            iconSrc:
              'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/gas-utility.svg',
            iconLabel: 'gas utility icon',
            iconBgColor: 'skyBlue',
            iconShrink: 2,
            title: 'GAS',
            description: 'Gas Line / Gas turbines supply 50 MW',
          },
          {
            iconSrc:
              'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/sewer-utility.svg',
            iconLabel: 'sewer utility icon',
            iconBgColor: 'skyBlue',
            iconShrink: 2.4,
            title: 'SEWER',
            description: '12” \n Main Line',
          },
        ],
      },
      featureTable: {
        tableHeadline: 'Why Choose Us?',
        tableCaption:
          'Unlike traditional data centers that try to handle all kinds of computing, our infrastructure is fine-tuned specifically for AI and machine learning applications. This means faster model training, reduced latency, and a more efficient workflow from end to end.',
        tableTitle: 'Data Center Highlights',
        tableImageSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/MainEntrance/l.webp',
        tableImageLabel: 'temporary image',
        tableLists: [
          {
            listTitle: 'AI-Optimized Hardware',
            listItems: [
              'Latest GPUs, TPUs, and specialized AI chips',
              'Tailored for deep learning and machine learning applications',
              'Accelerated training and inference speeds',
            ],
          },
          {
            listTitle: 'Scalability on Demand',
            listItems: [
              'Seamless data access with low latency',
              'Effortless resource provisioning',
            ],
          },
        ],
      },
      locationsMap: {
        mapTitle: 'Strategic Placement for Low Latency',
        mapCaption:
          'Our data centers are located in key technological hubs, ensuring minimal latency for AI operations, faster data transfers, and improved user experiences across the globe.',
        mapLocations: [{ lat: 34.863082, lng: -82.01883, label: 'SC1' }],
      },
      infoGrid2: {
        grid2Title: 'Let your data center grow with your needs',
        grid2Caption: 'Opportunities for future expansion are available.',
        grid2Items: [
          {
            itemTitle: 'Utilities',
            itemDescription:
              'Expansion potential for 300+ MW, with the option to add a substation or onsite generation. A 230 kV Duke Energy transmission lines and a gas pipeline on site to support future expansion beyond 100MW',
          },
          {
            itemTitle: 'Location',
            itemDescription:
              'Located within an industrial park with right-of-way for data center construction, the property offers 78 acres designated for development, an ample water supply, gas pipelines, and fast-track approvals',
          },
        ],
      },
    },
  },
};

export default content;
