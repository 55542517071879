const logos = {
  partners: [
    {
      id: '1',
      name: 'Holder Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/holder-logo-w/s.webp',
    },
    {
      id: '2',
      name: 'Evolve Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/evolve/s.webp',
    },
    {
      id: '3',
      name: 'AlfaTech Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/AlfaTech-w/s.webp',
    },
    {
      id: '4',
      name: 'Nortek Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/nortek-w/s.webp?v=2',
    },
    {
      id: '13',
      name: 'Blue Owl Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/blueowl-w/s.webp',
    },
    {
      id: '5',
      name: 'HP Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/hp-w/s.webp?v=2',
    },

    {
      id: '7',
      name: 'Lenovo Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/lenovo-w/s.webp?v=2',
    },
    {
      id: '8',
      name: 'SuperMicro Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/Super%20Micro-w/s.webp',
    },
    {
      id: '9',
      name: 'Brookfield Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/brookfield-w/s.webp?v=2',
    },
    {
      id: '10',
      name: 'Ares Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/Ares-w/s.webp',
    },
    {
      id: '11',
      name: 'Blackstone Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/Blackstone-w/s.webp',
    },
    {
      id: '6',
      name: 'Dell Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/dell-w/s.webp?v=2',
    },
    {
      id: '12',
      name: 'Apollo Logo',
      imageUrl:
        'https://storage.googleapis.com/tiger-dc-web/partner-logos/Apollo-w/s.webp',
    },
  ],
};

export default logos;
