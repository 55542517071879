import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: ${({ fit }) => fit};
`;

/**
 * An Image component that displays an image and provides ARIA attributes for accessibility.
 *
 * @param {string} src - The URL of the image
 * @param {string} label - A human-readable label of the image
 * @param {string} fit - The CSS object-fit property for the image. Defaults to 'cover'.
 * @return {ReactElement} A React element of the Image component
 */
const Image = ({ src, label, fit = 'cover', role = 'img' }) => {
  return (
    <Container>
      <StyledImage
        src={src}
        aria-label={label}
        alt={label}
        role={role}
        fit={fit}
        loading="lazy"
      />
    </Container>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fit: PropTypes.oneOf(['cover', 'contain', 'fill']),
};

export default Image;
