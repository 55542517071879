// ProfileCard.js

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ProfileAvatar from 'atoms/Avatar';

const Card = styled.div`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 325px;
  }

  @media (min-width: 1440px) {
  }
`;

const Name = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.blacks};
  margin: 2rem 0 1rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.5rem;
  }
`;

const Title = styled.p`
  ${({ theme }) => theme.mixins.textSubtitle};
  font-size: 2em;
  color: ${({ theme }) => theme.colors.greenAccent};
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`;

const ProfileDescription = styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  margin-top: 0.5rem;
  max-width: 500px;

  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`;

const ProfileAvatarWrapper = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  font-size: 8rem;

  @media (min-width: 768px) {
    width: 225px;
    height: 225px;
  }
`;

const ProfileCard = ({
  avatarSrc,
  profileName,
  profilePosition,
  profileDescription,
}) => {
  return (
    <Card>
      <ProfileAvatarWrapper>
        <ProfileAvatar src={avatarSrc} alt={profileName} />
      </ProfileAvatarWrapper>
      <Name>{profileName}</Name>
      <Title>{profilePosition}</Title>
      <ProfileDescription>{profileDescription}</ProfileDescription>
    </Card>
  );
};

ProfileCard.propTypes = {
  avatarSrc: PropTypes.string,
  profileName: PropTypes.string.isRequired,
  profilePosition: PropTypes.string,
  profileDescription: PropTypes.string,
};

export default ProfileCard;
