import styled from '@emotion/styled';
import Icon from 'atoms/Icon';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: clamp(0rem, 100%, 100rem);
  margin-top: 2rem;
  gap: clamp(2rem, 6vw, 4rem) clamp(4rem, 4vw, 8rem);

  ${({ theme }) => theme.mixins.flexColCenter};

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const Feature = styled.div`
  width: 100%;
  max-width: 40rem;
  ${({ theme }) => theme.mixins.flexRowCenter};
  gap: clamp(1rem, 2vw, 2rem) clamp(2rem, 3vw, 4rem);

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 25rem;
    ${({ theme }) => theme.mixins.flexColCenter};
  }
`;

const IconWrapper = styled.div`
  width: clamp(8rem, 8vw, 12rem);
  aspect-ratio: 1/1;
`;

const Label = styled.p`
  width: 48%;

  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.silver};
  text-align: left;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    text-align: center;
  }
`;

const Features = ({ features }) => {
  return (
    <Container>
      {features.map((feature, index) => (
        <Feature key={index}>
          <IconWrapper>
            <Icon
              iconName={feature.iconLabel}
              iconType="image"
              url={feature.iconSrc}
            />
          </IconWrapper>
          <Label>{feature.label}</Label>
        </Feature>
      ))}
    </Container>
  );
};
Features.propTypes = {
  features: PropTypes.array.isRequired,
};
export default Features;
