import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';

import SectionHeader from 'molecules/SectionHeader';
import ApproachStageCard from 'organisms/ApproachStageCard';
import content from 'data/approach';
import SEO from 'layouts/SEO';

const ApproachWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PageTop = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
  margin-top: 5rem;
`;
const Approach = () => {
  const { sectionTitle, sectionSubtitle } = content.headlineSection;
  const { stageCards } = content.stageCard;
  const { title, description, canonical } = content.seo;

  return (
    <ApproachWrapper>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <PageTop>
        <SectionHeader
          headline={sectionTitle}
          caption={sectionSubtitle}
          headlineColor="white"
        />
      </PageTop>
      {stageCards.map((stageCard, index) => (
        <ApproachStageCard
          key={index}
          cardBgColor={stageCard.cardBgColor}
          primaryTextColor={stageCard.primaryTextColor}
          secondaryTextColor={stageCard.secondaryTextColor}
          iconSrc={stageCard.iconSrc}
          iconLabel={stageCard.iconLabel}
          iconBgColor={stageCard.iconBgColor}
          iconShrink={stageCard.iconShrink}
          stageNumber={stageCard.stageNumber}
          stageTitle={stageCard.stageTitle}
          featureTexts={stageCard.featureTexts}
        />
      ))}
      <Footer />
    </ApproachWrapper>
  );
};
export default Approach;
