import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import Image from 'atoms/Image';
import React from 'react';
import PrimaryTitle from 'styles/PrimaryTitle';

const BannerWrapper = Styled.div`
  position: relative;
  height: 20rem;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    height: 40rem;
  }
`;

const BannerContent = Styled.div`
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  position: absolute;
  z-index: 2;
  text-align: center;
  height: 100%;
  width: calc(100% - 12%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
  width: calc(100% - 24%);
  }
  @media (min-width: 1440px) {
    width: calc(100% - 48%);
  }
`;

const BannerTitle = Styled(PrimaryTitle)`
`;

const BannerOverlay = Styled.div`
  background-color: ${({ theme, overlayColor }) => theme.colors[overlayColor]};
  opacity: ${({ overlayOpacity }) => [overlayOpacity] + '%'};
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
`;

const BannerImage = Styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: ${({ fit }) => fit};
`;
const Banner = ({
  src,
  alt,
  fit = 'cover',
  title,
  height = '100%',
  textColor = 'white',
  children,
  overlayColor,
  overlayOpacity,
}) => {
  return (
    <BannerWrapper height={height}>
      <BannerContent textColor={textColor}>
        {title && <BannerTitle>{title}</BannerTitle>}
        {children}
      </BannerContent>
      <BannerOverlay
        overlayColor={overlayColor}
        overlayOpacity={overlayOpacity}
      ></BannerOverlay>
      <BannerImage src={src} label={alt} fit={fit} />
    </BannerWrapper>
  );
};

Banner.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  fit: PropTypes.oneOf(['cover', 'contain', 'fill', 'none', 'scale-down']),
  title: PropTypes.string,
  height: PropTypes.string,
  textColor: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
};

export default Banner;
