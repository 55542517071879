import Styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import SecondaryTitle from 'styles/SecondaryTitle';
import Subtitle from 'styles/Subtitle';
import { Link } from 'react-router-dom';

import SEO from 'layouts/SEO';

const StyledTeam = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  height: 100vh;
  width: 100%;
`;

const Notice = Styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
`;
const LinkMessage = Styled(Subtitle)`
  transition: color 0.2s ease-in-out;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const NotFound = () => (
  <div>
    <SEO title="404" noindex={true} />
    <Header />
    <StyledTeam>
      <Notice>404 - Not Found</Notice>
      <Link to="/">
        <LinkMessage>Return to Home</LinkMessage>
      </Link>
    </StyledTeam>
    <Footer />
  </div>
);

export default NotFound;
