import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Icon from 'atoms/Icon';
import PropTypes from 'prop-types';

//TO DO: switch out font size with theme value when updated.

const Container = styled.button`
  background: none;
  ${({ theme }) => theme.mixins.flexRowCenter}
  gap: 0.5em;
  padding: clamp(1rem, 0.8vw, 1.2rem) clamp(1.6rem, 1.2vw, 2.2rem);
  border: 2px solid ${({ theme, color }) => theme.colors[color]};
  border-radius: 9999px;

  ${({ theme }) => theme.mixins.textP};
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme, color }) => theme.colors[color]};

  transition:
    color 0.2s ease,
    border-color 0.2s ease;
  cursor: pointer;

  :hover {
    color: ${({ theme, hoverColor }) => theme.colors[hoverColor]};
    border-color: ${({ theme, hoverColor }) => theme.colors[hoverColor]};
  }
`;

const Button = ({
  url,
  label = 'Button',
  color = 'black',
  hoverColor = 'greenAccent',
  iconName = 'arrowRight',
  type = 'button',
}) => {
  return (
    <Link to={url} aria-label={label}>
      <Container color={color} hoverColor={hoverColor} type={type}>
        {label}
        <Icon iconName={iconName} type="solid" size="1x" />
      </Container>
    </Link>
  );
};
Button.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  iconName: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
};
export default Button;
