import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Logo = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ size = 1.6 }) => `${size}rem`};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: -0.02em;
`;

const AccentColor = styled.span`
  color: ${({ theme }) => theme.colors.greenAccent};
`;

const TigerLogo = ({ size }) => {
  return (
    <Logo size={size}>
      <Link to="/" onClick={() => setMenuOpen(false)}>
        <AccentColor>Tiger</AccentColor>DC
      </Link>
    </Logo>
  );
};
TigerLogo.propTypes = {
  size: PropTypes.number,
};
export default TigerLogo;
