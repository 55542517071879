import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Marquee from 'molecules/Marquee';

const Container = styled.section`
  width: 100%;
  padding: clamp(2rem, 3vw, 4rem) 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: clamp(1rem, 3vw, 4rem);

  background: ${({ theme }) => theme.colors.black};
`;

const TitleWrapper = styled.div`
  align-self: center;
`;

const Title = styled.h2`
  ${({ theme }) => theme.mixins.textSubtitle};
  text-align: center;
  color: ${({ theme }) => theme.colors.greenAccent};
  padding: 0 clamp(1rem, 6vw, 8rem);
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;
`;

const Logo = styled.img`
  max-height: 3rem;
  width: auto;

  object-fit: contain;

  ${({ theme }) => theme.mediaQueries.md} {
    max-height: 5rem;
  }
`;

const PartnersBar = ({
  partners,
  title = 'In Partnership with Industry Leaders',
}) => (
  <Container>
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <Marquee>
      {partners.map(partner => (
        <LogoWrapper key={partner.id}>
          <Logo src={partner.imageUrl} alt={partner.name} loading="lazy" />
        </LogoWrapper>
      ))}
    </Marquee>
  </Container>
);

PartnersBar.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
};

export default PartnersBar;
