const links = [
  { url: '/datacenters', label: 'Data Centers' },
  { url: '/datacenters/sc1', label: 'SC1' },
  { url: '/cooling', label: 'Cooling' },
  { url: '/approach', label: 'Approach' },
  { url: '/team', label: 'Team' },
  { url: '/partners', label: 'Partners' },
  // { url: '/news', label: 'News' },
  { url: '/contact', label: 'Contact' },
];
export default links;
