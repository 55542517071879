import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import SolutionsFeature from 'organisms/SolutionsFeatureCard';

const SolutionsFeaturesWrapper = Styled.div`
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(1rem, 1vw, 2rem);
`;

const SolutionsFeatureSection = ({ solutions }) => (
  <SolutionsFeaturesWrapper>
    {solutions.map((solution, index) => (
      <SolutionsFeature
        key={index}
        imgSrc={solution.imgSrc}
        imgLabel={solution.imgLabel}
        imgFit={solution.imgFit}
        infoTitle={solution.infoTitle}
        infoSubtitle={solution.infoSubtitle}
        bgColor={solution.bgColor}
        solutionTextColor={solution.solutionTextColor}
        featureTextColor={solution.featureTextColor}
        featureIconName={solution.featureIconName}
        featureTexts={solution.featureTexts}
      />
    ))}
  </SolutionsFeaturesWrapper>
);

SolutionsFeatureSection.propTypes = {
  solutions: PropTypes.array.isRequired,
};

export default SolutionsFeatureSection;
