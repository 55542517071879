import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border: none;
  * {
    border: none !important;
  }
`;

/**
 * @function GoogleMap
 * @description A React component that renders a Google Map with markers for multiple locations.
 * @param {Array<Object>} locations - An array of location objects to place markers on.
 * @returns {ReactElement} A React component that renders a Google Map.
 */
const GoogleMap = ({ locations }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loadMap = async () => {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['marker'],
      });

      const google = await loader.load();
      const geocoder = new google.maps.Geocoder();
      const bounds = new google.maps.LatLngBounds();

      if (!Array.isArray(locations) || locations.length === 0) {
        // Invalid or empty locations; render empty map
        new google.maps.Map(mapRef.current, {
          center: { lat: 0, lng: 0 },
        });
        // eslint-disable-next-line no-console
        console.error('No location array found.');
        return;
      }

      const geocodePromises = locations.map(locationInfo => {
        const { lat, lng, label } = locationInfo;

        if (lat != null && lng != null) {
          // Coordinates are provided; use them directly
          return Promise.resolve({
            position: new google.maps.LatLng(lat, lng),
            title: label || '',
          });
        }
        // Coordinates not provided; attempt to geocode the address
        const addressString = buildAddressString(locationInfo);
        if (addressString) {
          return new Promise(resolve => {
            geocoder.geocode({ address: addressString }, (results, status) => {
              if (status === 'OK' && results[0]) {
                resolve({
                  position: results[0].geometry.location,
                  title: label || addressString,
                });
              } else {
                // eslint-disable-next-line no-console
                console.error(
                  `Geocode was not successful for the following reason: ${status}`
                );
                resolve(null);
              }
            });
          });
        }
        // No coordinates or address; skip this location
        // eslint-disable-next-line no-console
        console.error('No coordinates or address provided for a location.');
        return Promise.resolve(null);
      });

      const markerLocations = await Promise.all(geocodePromises);

      // Filter out any null results due to failures
      const validLocations = markerLocations.filter(loc => loc !== null);

      if (validLocations.length > 0) {
        const map = new google.maps.Map(mapRef.current, {
          center: validLocations[0].position,
          zoom: 14, // Default zoom level
          maxZoom: 14, // Enforce a max zoom level across all cases
          fullscreenControl: true,
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: false,
          mapId: 'tiger-dc-map',
        });

        validLocations.forEach(({ position, title }) => {
          new google.maps.marker.AdvancedMarkerElement({
            map: map,
            position: position,
            title: title,
          });
          bounds.extend(position);
        });

        if (validLocations.length === 1) {
          // For a single location, set the center and zoom level manually
          map.setCenter(validLocations[0].position);
          map.setZoom(14); // Ensure it's at your desired zoom level
        } else {
          // For multiple locations, fit bounds but limit the zoom
          map.fitBounds(bounds, { padding: 50 });
          const listener = google.maps.event.addListenerOnce(
            map,
            'bounds_changed',
            () => {
              if (map.getZoom() > 14) {
                map.setZoom(14); // Enforce max zoom level
              }
              google.maps.event.removeListener(listener); // Remove listener after applying zoom check
            }
          );
        }
      } else {
        // No valid locations; render empty map
        new google.maps.Map(mapRef.current, {
          center: { lat: 0, lng: 0 },
          zoom: 14,
        });
        // eslint-disable-next-line no-console
        console.error('No valid locations to display on the map.');
      }
    };

    const buildAddressString = addressInfo => {
      const { address1, address2, city, state, zip } = addressInfo;
      const addressParts = [address1, address2, city, state, zip];
      const addressString = addressParts.filter(Boolean).join(', ');
      return addressString || null;
    };

    loadMap();
  }, [locations]);

  return <MapContainer ref={mapRef} />;
};

GoogleMap.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default GoogleMap;

// Exmaple data and usage

// <GoogleMap locations={locations} />

// const locations = [
//   {
//     lat: 34.863082,
//     lng: -82.01883,
//     label: 'SC1',
//   },
//   {
//     address1: '1540 Broadway Ste 1010',
//     city: 'New York',
//     state: 'NY',
//     zip: '10036',
//     label: 'VCV',
//   },
// ];
