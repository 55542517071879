import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Create a context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  // Define all global state here
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const location = useLocation();
  const isActive = path => currentPath === path;

  // Define any functions that modify the state
  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  // Combine the state and functions into a single value
  const value = {
    menuOpen,
    setMenuOpen,
    toggleMenu,
    currentPath,
    isActive,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
