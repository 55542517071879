const maxHeight = '1200px';
const maxWidth = '1440px';
const pagePadding = 'clamp(4rem, 5vw, 8rem) clamp(1rem, 6vw, 8rem)';
const navPadding = 'clamp(2.8rem, 3vw, 4rem) clamp(1rem, 6vw, 8rem)';

const layouts = {
  pageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: pagePadding,
  },
  pageContent: {
    // maxHeight: maxHeight,
    maxWidth: maxWidth,
    width: '100%',
  },

  maxHeight: maxHeight,
  maxWidth: maxWidth,
  pagePadding: pagePadding,
  navPadding: navPadding,
};
export default layouts;
