import styled from '@emotion/styled';
import SecondaryTitle from 'styles/SecondaryTitle';
import Subtitle from 'styles/Subtitle';
import PropTypes from 'prop-types';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(1.5rem, 2vw, 3rem);
`;

const Headline = styled.h3`
  width: 100%;
  ${({ theme }) => theme.mixins.textH3};
  color: ${({ theme, headlineColor }) => theme.colors[headlineColor]};
  text-align: left;
  max-width: 80rem;
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;
const Caption = styled.p`
  width: 100%;
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme, captionColor }) => theme.colors[captionColor]};
  text-align: left;
  max-width: 100rem;
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;
const SectionHeader = ({
  headline,
  caption,
  headlineColor = 'text',
  captionColor = 'text',
}) => {
  return (
    <Container>
      <Content>
        <Headline headlineColor={headlineColor}>{headline}</Headline>
        <Caption captionColor={captionColor}>{caption}</Caption>
      </Content>
    </Container>
  );
};

SectionHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default SectionHeader;
