const content = {
  seo: {
    title: 'TigerDC | Partners',
    description: 'Our partners who help make TigerDC possible.',
    canonical: '/partners',
  },
  headlineSection: {
    pageTitle: 'Partnerships',
    pageSubtitle: 'Our partners who help make TigerDC possible.',
  },
  logoSection: {
    logos: [
      {
        bgColor: 'silver',
        titleColor: 'white',
        containerTitle: 'General Contractor',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/holder-logo-w/t.webp',
            imgLabel: 'Holder Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/evolve/s.webp',
            imgLabel: 'Evolve Logo',
          },
        ],
      },
      {
        bgColor: 'slate',
        titleColor: 'white',
        containerTitle: 'MEP',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/AlfaTech-w/m.webp',
            imgLabel: 'AlfaTech Logo',
          },
        ],
      },
      {
        bgColor: 'charcoal',
        titleColor: 'white',
        containerTitle: 'OEM',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/nortek-w/l.webp',
            imgLabel: 'Nortek Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/dell-w/t.webp?v=2',
            imgLabel: 'Dell Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/hp-w/t.webp',
            imgLabel: 'HP Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Super%20Micro-w/s.webp',
            imgLabel: 'Supermicro Logo',
          },
        ],
      },
      {
        bgColor: 'darkBlue',
        titleColor: 'white',
        containerTitle: 'Banks',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/blueowl-w/m.webp',
            imgLabel: 'Blue Owl Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Blackstone-w/m.webp',
            imgLabel: 'Blackstone Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Ares-w/m.webp',
            imgLabel: 'Ares Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Apollo-w/m.webp',
            imgLabel: 'Apollo Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/brookfield-w/s.webp?v=2',
            imgLabel: 'Brookfield Logo',
          },
        ],
      },

      // {
      //   bgColor: 'slate',
      //   titleColor: 'offWhite',
      //   containerTitle: 'Cooling Solutions Provider',
      //   logos: [
      //     {
      //       imgSrc:
      //         'https://storage.googleapis.com/tiger-dc-web/partner-logos/Accelsius-w/m.webp',
      //       imgLabel: 'Accelsius Logo',
      //     },
      //     {
      //       imgSrc:
      //         'https://storage.googleapis.com/tiger-dc-web/partner-logos/Opticool-w/m.webp',
      //       imgLabel: 'Opticool Logo',
      //     },
      //     {
      //       imgSrc:
      //         'https://storage.googleapis.com/tiger-dc-web/partner-logos/ECS-w/m.webp',
      //       imgLabel: 'ECS Logo',
      //     },
      //   ],
      // },
    ],
  },
};
export default content;
