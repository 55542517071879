const colors = {
  white: '#F8F8F8',
  black: '#0F1111',
  darkBlue: '#0D1A2A',
  lightBlue: '#4D91D0',
  silver: '#9D9EA2',
  slate: '#47474C',
  charcoal: '#2A2B2E',
  greenAccent: '#1DCE5B',
  background: '#F8F8F8',
  text: '#9D9EA2',
  activeDot: 'rgba(255, 255, 255, 1)',
  inactiveDot: 'rgba(255, 255, 255, 0.6)',
  overlay: '#000',
  scrollToTop: {
    background: '#1DCE5B',
    backgroundHover: '#8996A7',
    icon: '#fff',
    iconHover: '#000',
    shadow: '#484848',
  },
};
export default colors;
