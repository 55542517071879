import { memo } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'hooks/useLocation';
import DataCenterLayout from 'layouts/DataCenterLayout';
import PageHero from 'molecules/PageHero';
import SectionHeader from 'molecules/SectionHeader';
import Banner from 'molecules/Banner';
import InfoGrid from 'organisms/InfoGrid';
import FeatureTable from 'organisms/FeatureTable';
import LocationsMap from 'organisms/LocationsMap';

const MemoizedInfoGrid = memo(InfoGrid);
const MemoizedFeatureTable = memo(FeatureTable);
const MemoizedLocationsMap = memo(LocationsMap);

import PageFeatureBanner from 'molecules/PageFeatureBanner';

const Section = styled.section`
  ${({ theme }) => theme.mixins.flexColCenter};
`;
const IntroSection = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
`;

import locations from 'data/locations';

const Location = () => {
  const { config } = useLocation();

  if (!config) {
    return null;
  }

  const {
    contentTop: { topHeadline, topCaption },
    banner: { bannerSrc, bannerAltLabel },
    infoGrid: { gridTitle, gridItems },
    iconStrip: {
      iconStripBgColor,
      iconStripTitleColor,
      iconTitleColor,
      iconDescriptionColor,
      iconStripTitle,
      iconStripIcons,
    },
    featureTable: {
      tableHeadline,
      tableCaption,
      tableImageSrc,
      tableImageLabel,
      tableTitle,
      tableLists,
    },
    locationsMap: { mapTitle, mapCaption, mapLocations },
    infoGrid2: { grid2Title, grid2Caption, grid2Items },
  } = config.about;

  const { welcomeSection, featureBanner } = locations;
  return (
    <>
      <DataCenterLayout>
        <PageHero
          content={welcomeSection}
          highlightIndex={2}
          alignLines={[1, 2]}
        />
        <IntroSection>
          <SectionHeader
            headline={topHeadline}
            caption={topCaption}
            headlineColor="white"
          />
          <Banner src={bannerSrc} alt={bannerAltLabel} />
        </IntroSection>

        <Section>
          <MemoizedInfoGrid title={gridTitle} gridItems={gridItems} />
        </Section>

        <PageFeatureBanner content={featureBanner} />

        <Section>
          <SectionHeader
            headline={tableHeadline}
            caption={tableCaption}
            headlineColor="black"
          />
          <MemoizedFeatureTable
            tableImageSrc={tableImageSrc}
            tableImageLabel={tableImageLabel}
            tableTitle={tableTitle}
            tableLists={tableLists}
          />
        </Section>

        <Section>
          <SectionHeader
            headline={mapTitle}
            caption={mapCaption}
            headlineColor="black"
          />
          <MemoizedLocationsMap mapLocations={mapLocations} />
        </Section>

        <Section>
          <MemoizedInfoGrid
            title={grid2Title}
            caption={grid2Caption}
            gridItems={grid2Items}
          />
        </Section>
      </DataCenterLayout>
    </>
  );
};

export default memo(Location);
