import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import SecondaryTitle from 'styles/SecondaryTitle';
import Subtitle from 'styles/Subtitle';
import FeatureIcons from 'molecules/FeatureIcons';

const Container = Styled.div`
  ${({ theme }) => theme.layouts.pageContainer};
  background-color: ${({ theme, cardBgColor }) => theme.colors[cardBgColor]};
  overflow: hidden;
  ${({ theme }) => theme.mixins.flexColCenter};

`;
const StageNumber = Styled(SecondaryTitle)`
  color: ${({ theme, primaryTextColor }) => theme.colors[primaryTextColor]};
  padding-top: 1rem;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 2rem;
  }
  `;
const Title = Styled(SecondaryTitle)`
  color: ${({ theme, primaryTextColor }) => theme.colors[primaryTextColor]};
  padding-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 4rem;
  }
`;
const Feature = Styled.div`
  text-align: center;
  ${({ theme }) => theme.mixins.flexColCenter};
`;
const FeatureTitle = Styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.silver};
  font-weight: 700;
  color: ${({ theme, secondaryTextColor }) => theme.colors[secondaryTextColor]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-bottom: 0.25rem;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 0;
  }
`;
const FeatureDescription = Styled.p`
${({ theme }) => theme.mixins.textP};
    color: ${({ theme }) => theme.colors.silver};
    width: 80%;
    padding-bottom: 1rem;

    ${({ theme }) => theme.mediaQueries.md} {
      padding-bottom: 2rem;
    }
`;

const ApproachStageCard = ({
  cardBgColor,
  primaryTextColor,
  secondaryTextColor,
  iconSrc,
  iconLabel,
  iconBgColor,
  iconShrink,
  stageNumber,
  stageTitle,
  featureTexts,
}) => {
  return (
    <Container>
      <FeatureIcons
        iconSrc={iconSrc}
        iconLabel={iconLabel}
        iconBgColor={iconBgColor}
        iconShrink={iconShrink}
      />
      <StageNumber>{stageNumber}</StageNumber>
      <Title>{stageTitle}</Title>

      {featureTexts.map(({ featureTitle, featureDescription }, index) => (
        <Feature key={index}>
          <FeatureTitle>{featureTitle}</FeatureTitle>
          <FeatureDescription>{featureDescription}</FeatureDescription>
        </Feature>
      ))}
    </Container>
  );
};

ApproachStageCard.propTypes = {
  cardBgColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  iconLabel: PropTypes.string.isRequired,
  iconBgColor: PropTypes.string,
  iconShrink: PropTypes.number.isRequired,
  stageNumber: PropTypes.string.isRequired,
  stageTitle: PropTypes.string.isRequired,
  featureTexts: PropTypes.arrayOf(
    PropTypes.shape({
      featureTitle: PropTypes.string,
      featureDescription: PropTypes.string,
    })
  ),
};

export default ApproachStageCard;
