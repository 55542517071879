import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Image from 'atoms/Image';
import Icon from 'atoms/Icon';

const Solution = styled.div`
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }
`;

const SolutionImageWrapper = styled.div`
  flex: 1;

  img {
    height: 16rem;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    img {
      height: 100%;
    }
  }
`;

const SolutionInfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: clamp(0.4rem, 1vw, 1rem);
  padding: clamp(1rem, 1vw, 2rem);
  margin-bottom: clamp(1rem, 1vw, 2rem);

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`;

const InfoTitle = styled.h4`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme, solutionTextColor }) => theme.colors[solutionTextColor]};
  align-self: center;

  ${({ theme }) => theme.mediaQueries.lg} {
    align-self: inherit;
  }
`;

const InfoSubtitle = styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme, solutionTextColor }) => theme.colors[solutionTextColor]};
  align-self: center;
  text-align: center;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.lg} {
    align-self: inherit;
    text-align: left;
    width: 100%;
    margin-bottom: 2rem;
  }
`;
const Highlights = styled.ul`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  gap: clamp(0.4rem, 2vw, 0.8rem);
  ${({ theme }) => theme.mixins.flexColCenter};
  align-items: flex-start;
`;

const HighlightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  svg {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
`;

const Highlight = styled.span`
  margin-left: 0.6em;
`;

const SolutionsFeatureCard = ({
  imgSrc,
  imgLabel,
  imgFit,
  infoTitle,
  infoSubtitle,
  bgColor,
  featureIconName,
  solutionTextColor,
  featureTexts,
  featureTextColor,
}) => {
  return (
    <Solution bgColor={bgColor}>
      <SolutionImageWrapper>
        <Image src={imgSrc} label={imgLabel} fit={imgFit} />
      </SolutionImageWrapper>
      <SolutionInfoWrapper>
        <InfoTitle solutionTextColor={solutionTextColor}>{infoTitle}</InfoTitle>
        <InfoSubtitle solutionTextColor={solutionTextColor}>
          {infoSubtitle}
        </InfoSubtitle>
        <Highlights>
          {featureTexts.map((featureText, index) => (
            <HighlightWrapper key={index}>
              <Icon iconName="check" />
              <Highlight>{featureText}</Highlight>
            </HighlightWrapper>
          ))}
        </Highlights>
      </SolutionInfoWrapper>
    </Solution>
  );
};
SolutionsFeatureCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgLabel: PropTypes.string.isRequired,
  imgFit: PropTypes.string.isRequired,
  infoTitle: PropTypes.string,
  infoSubtitle: PropTypes.string,
  bgColor: PropTypes.string,
  featureIconName: PropTypes.string.isRequired,
  featureTextColor: PropTypes.string,
  solutionTextColor: PropTypes.string,
  featureTexts: PropTypes.array.isRequired,
};

export default SolutionsFeatureCard;
