import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PrimaryTitle from 'styles/PrimaryTitle';
import SecondaryTitle from 'styles/SecondaryTitle';
import Subtitle from 'styles/Subtitle';

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  align-items: flex-start;
  gap: clamp(1.5rem, 2vw, 3rem);

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: center;
  }
`;

const Title = styled.h3`
  ${({ theme }) => theme.mixins.textH3};
  color: ${({ theme, headlineColor }) => theme.colors[headlineColor]};
  text-align: left;
  max-width: ${({ titleWidth }) => `${titleWidth}rem`};
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;
const Caption = styled.p`
  width: 100%;
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  max-width: ${({ subtitleWidth }) => `${subtitleWidth}rem`};
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;

const HeadlineSection = ({
  titleWidth,
  subtitleWidth,
  sectionTitle,
  sectionSubtitle,
}) => {
  return (
    <Content>
      <Title titleWidth={titleWidth}>{sectionTitle}</Title>
      {sectionSubtitle && (
        <Caption subtitleWidth={subtitleWidth}>{sectionSubtitle}</Caption>
      )}
    </Content>
  );
};

HeadlineSection.propTypes = {
  titleType: PropTypes.oneOf(['primary', 'secondary']),
  paddingPreset: PropTypes.oneOf(['topOfPage', 'insidePage']),
  titleWidth: PropTypes.number,
  subtitleWidth: PropTypes.number,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  sectionSubtitle: PropTypes.string,
};

export default HeadlineSection;
