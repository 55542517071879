import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(4rem, 5vw, 8rem);
`;

const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mixins.textH3};
  text-align: left;

  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;

const Caption = styled.p`
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.mixins.textP};
  text-align: left;

  ${({ theme }) => theme.mediaQueries.md} {
    text-align: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  grid-gap: clamp(2rem, 5vw, 4rem);
  grid-column-gap: clamp(2rem, 5vw, 8rem);
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

const ItemTitle = styled.h4`
  ${({ theme }) => theme.mixins.textP};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`;

const ItemDescription = styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  max-width: 120rem;
`;

const InfoGrid = ({ title, caption, gridItems }) => {
  return (
    <Container>
      <Content>
        <TitleArea>
          <Title>{title}</Title>
          {caption && <Caption>{caption}</Caption>}
        </TitleArea>
        <Grid>
          {gridItems.map((gridItem, index) => (
            <GridItem key={index}>
              <ItemTitle as="h4">{gridItem.itemTitle}</ItemTitle>
              <ItemDescription>{gridItem.itemDescription}</ItemDescription>
            </GridItem>
          ))}
        </Grid>
      </Content>
    </Container>
  );
};

InfoGrid.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemTitle: PropTypes.string.isRequired,
      itemDescription: PropTypes.string.isRequired,
    })
  ),
};

export default InfoGrid;
