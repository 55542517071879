// src/pages/Datacenters.jsx
import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';

import content from 'data/datacenters';
import SectionHeader from 'molecules/SectionHeader';

import DatacentersTemplate from 'organisms/DatacentersTemplate';

import SEO from 'layouts/SEO';

const StyledDatacenter = styled.div`
  ${({ theme }) => theme.mixins.flexColCenter};
  justify-content: flex-start;
  height: 100vh;
`;
const PageTop = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  margin-top: 5rem;
`;
const Datacenters = () => {
  const datacenters = content.identifiers;
  const { title, description, canonical } = content.seo;

  return (
    <StyledDatacenter>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />

      <PageTop>
        <SectionHeader
          headline={'Our Data Centers'}
          caption={'Our AI Focused Data Center'}
          headlineColor="white"
        />
      </PageTop>

      {datacenters.map(location => {
        if (content[location]) {
          return (
            <DatacentersTemplate
              key={location}
              location={location}
              data={content[location]}
            />
          );
        }
      })}
      <Footer />
    </StyledDatacenter>
  );
};

export default Datacenters;
