import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: conic-gradient(
      from 0deg,
      ${({ theme }) => theme.colors.white},
      ${({ theme }) => theme.colors.greenAccent},
      ${({ theme }) => theme.colors.darkBlue},
      ${({ theme }) => theme.colors.white}
    );
    animation: ${({ $isLoading }) =>
      $isLoading ? 'spin 1s linear infinite' : 'none'};
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 2;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 3;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-radius: 50%;
  z-index: 3;
`;

const ProfileAvatar = ({ src, alt, placeholder }) => {
  const [isLoading, setIsLoading] = useState(!!src);
  const [hasError, setHasError] = useState(false);

  const handleImageLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleImageError = useCallback(() => {
    setHasError(true);
    setIsLoading(false);
  }, []);

  return (
    <AvatarWrapper aria-label={alt} $isLoading={isLoading}>
      {src && !hasError ? (
        <AvatarImage
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          $isLoading={isLoading}
          loading="lazy"
        />
      ) : (
        <Placeholder>{placeholder || alt.charAt(0).toUpperCase()}</Placeholder>
      )}
    </AvatarWrapper>
  );
};

ProfileAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default React.memo(ProfileAvatar);
