import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import ContactForm from 'organisms/ContactForm';
import PageHeader from 'molecules/PageHeader';

import SEO from 'layouts/SEO';

const ContactWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  margin-bottom: 6rem;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;
const ContactWrapperStyled = styled.div`
  box-sizing: border-box;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Contact = () => (
  <>
    <SEO title="TigerDC | Contact" canonical="/contact" />
    <Header />
    <ContactWrapper>
      <PageHeader title="Contact" subtitle="Get in touch with us today." />
      <ContactWrapperStyled>
        <ContactForm />
      </ContactWrapperStyled>
    </ContactWrapper>
    <Footer />
  </>
);

export default Contact;
