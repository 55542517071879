import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import styled from '@emotion/styled';
import NavMenu from 'organisms/NavMenu';
import links from 'data/navigation';
import MobileMenuButton from 'molecules/MenuButtonMobile';
import NavMenuMobile from 'organisms/NavMenuMobile';
import useNoScroll from 'hooks/useNoScroll';

const Container = styled.header`
  height: 6rem;
  width: 100%;
  ${({ theme }) => theme.mixins.flexRowCenter};
  background-color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => theme.layouts.navPadding};
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.header};
`;
const Content = styled.div`
  max-width: ${({ theme }) => theme.layouts.maxWidth};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const AccentColor = styled.span`
  color: ${({ theme }) => theme.colors.greenAccent};
`;

const Header = () => {
  const { setMenuOpen, toggleMenu, menuOpen } = useContext(GlobalContext);
  useNoScroll(menuOpen);
  return (
    <Container>
      <Content>
        <Logo>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <AccentColor>Tiger</AccentColor>DC
          </Link>
        </Logo>
        <MobileMenuButton onClick={toggleMenu} isOpen={menuOpen} />
        <NavMenu links={links} />
        <NavMenuMobile links={links} />
      </Content>
    </Container>
  );
};

export default Header;
