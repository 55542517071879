import styled from '@emotion/styled';
import GoogleMap from 'molecules/Map';
import PropTypes from 'prop-types';

const MapWrapper = styled.div`
  height: 100vw;
  width: 100vw;

  @media (min-width: 768px) {
    max-height: calc(100vw / 2.39);
  }
  @media (min-width: 1440px) {
    max-height: calc(100vw / 3.55);
  }
`;
const LocationsMap = ({ mapLocations }) => {
  return (
    <MapWrapper>
      <GoogleMap locations={mapLocations} />
    </MapWrapper>
  );
};

LocationsMap.propTypes = {
  mapLocations: PropTypes.array.isRequired,
};
export default LocationsMap;
