import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import TigerDcLogo from 'atoms/TigerLogo';
import links from 'data/navigation';
import Icon from 'atoms/Icon';

const Container = styled.footer`
  ${({ theme }) => theme.layouts.pageContainer};
  padding: ${({ theme }) => theme.layouts.navPadding};
  background-color: ${({ theme }) => theme.colors.black};
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};

  ${({ theme }) => theme.mixins.textNav};
  color: ${({ theme }) => theme.colors.silver};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Meta = styled.div`
  align-self: stretch;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(0.6rem, 0.4vw, 0.8rem);
`;

const Copyright = styled(Link)`
  ${({ theme }) => theme.mixins.flexRowCenter};
  justify-content: flex-start;
  gap: 0.4em;
`;

const SiteMap = styled.nav`
  height: 100%;

  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;
  gap: clamp(0.6rem, 0.4vw, 0.8rem) clamp(2rem, 5vw, 8rem);
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const { setMenuOpen } = useContext(GlobalContext); // Access context

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu
  };

  return (
    <Container>
      <Content>
        <Meta>
          <TigerDcLogo size={2.4} />
          <Contact>
            <p>info@vcvdigital.com</p>
            <Copyright to="/" onClick={() => setMenuOpen(false)}>
              <Icon iconName="copyright" />
              TigerDC {currentYear}
            </Copyright>
          </Contact>
        </Meta>
        <SiteMap>
          {links.map(({ url, label }) => (
            <Link
              key={url}
              to={url}
              onClick={handleLinkClick}
              aria-label={label}
            >
              {label}
            </Link>
          ))}
        </SiteMap>
      </Content>
    </Container>
  );
};

export default Footer;
