// hooks/useLocation.js
import { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import locations from 'data/datacenters';

export const useLocation = () => {
  const { location } = useParams();
  const navigate = useNavigate();

  const config = useMemo(() => {
    const locationId = location?.toLowerCase();
    const config = locations[locationId];

    if (!config) {
      // Redirect to 404 or default page if location doesn't exist
      navigate('/404', { replace: true });
      return null;
    }

    return config;
  }, [location, navigate]);

  return { config, locationId: location?.toLowerCase() };
};
