import buttons from './buttons';
import colors from './colors';
import fonts from './fonts';
import layouts from './layouts';
import mediaQueries from './mediaQueries';
import mixins from './mixins';
import zIndices from './zIndices';

const theme = {
  buttons,
  colors,
  fonts,
  layouts,
  mediaQueries,
  mixins,
  zIndices,

  fontSizes: {
    xsmall: '1rem',
    small: '1.2rem',
    regular: '1.6rem',
    medium: '2rem',
    large: '3.6rem',
    xlarge: '5rem',
    xxlarge: '12.8rem',
  },
  fontWeights: {
    regular: '400',
    medium: '500',
    bold: '700',
    extrabold: '800',
  },
  letterSpacing: {
    headline: '-0.5rem',
    headlineSmall: '-0.1rem',
    body: '0.01rem',
    bodySmall: '0.01rem',
  },
  lineHeight: {
    headlineSmall: '5rem',
    headlineRegular: '12rem',
    bodySmall: '1.5rem',
    bodyRegular: '3rem',
  },
};

export default theme;
