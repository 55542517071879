import Styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';

import ComparisonSection from 'layouts/ComparisonSection';
import FutureProofSection from 'organisms/FutureProofSection';
import SolutionsSection from 'organisms/SolutionsSection';
import PageHero from 'molecules/PageHero';

import SEO from 'layouts/SEO';
import content from 'data/cooling';

const Cooling = () => {
  const { title, description, canonical } = content.seo;
  const { welcomeSection } = content;

  return (
    <>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <PageHero
        content={welcomeSection}
        highlightIndex={1}
        alignLines={[1, 2]}
      />
      <ComparisonSection />
      <FutureProofSection />
      <SolutionsSection />
      <Footer />
    </>
  );
};

export default Cooling;
