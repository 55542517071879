import React from 'react';
import Styled from '@emotion/styled';
import PropTypes from 'prop-types';

import TableItems from 'molecules/TableItems';

const TableWrapper = Styled.table`
  width: 100%;  
  table-layout: fixed;
`;

const TableHeader = Styled.thead`
  height: 6rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.body};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: 768px) {
    height: 8rem;
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }

  @media (min-width: 1024px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }

  @media (min-width: 1600px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const RowWrapper = Styled.tr`
  height: inherit;
  ${({ theme }) => theme.mixins.textP};
 font-family: ${({ theme }) => theme.fonts.primary};
`;

const HeadCellLeft = Styled.th`
  background-color: ${({ theme, headLeftBgColor }) => theme.colors[headLeftBgColor]};
  text-align: center;
  vertical-align: middle;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const HeadCellCenter = Styled.th`
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, headCenterBgColor }) => theme.colors[headCenterBgColor]};
  text-align: center;
  vertical-align: middle;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const HeadCellRight = Styled.th`
  background-color: ${({ theme, headRightBgColor }) => theme.colors[headRightBgColor]};
  text-align: center;
  vertical-align: middle;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const HeadCellFarRight = Styled.th`
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, headFarRightBgColor }) => theme.colors[headFarRightBgColor]};
  text-align: center;
  vertical-align: middle;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const TableSection = ({
  tableItems,
  headerLeft,
  headerCenter,
  headerRight,
  headerFarRight,
  headLeftBgColor,
  headCenterBgColor,
  headRightBgColor,
  headFarRightBgColor,
  cellTextColor,
  columnLeftBgColor,
  columnCenterBgColor,
  columnRightBgColor,
  columnFarRightBgColor,
}) => {
  return (
    <TableWrapper>
      <TableHeader>
        <RowWrapper>
          <HeadCellLeft headLeftBgColor={headLeftBgColor}>
            {headerLeft}
          </HeadCellLeft>
          <HeadCellCenter headCenterBgColor={headCenterBgColor}>
            {headerCenter}
          </HeadCellCenter>
          <HeadCellRight headRightBgColor={headRightBgColor}>
            {headerRight}
          </HeadCellRight>
          {headerFarRight && headerFarRight.trim() && (
            <HeadCellFarRight headFarRightBgColor={headFarRightBgColor}>
              {headerFarRight}
            </HeadCellFarRight>
          )}
        </RowWrapper>
      </TableHeader>
      {tableItems.map((tableItem, index) => (
        <TableItems
          key={index}
          cellTextColor={cellTextColor}
          columnLeft={tableItem.columnLeft}
          columnCenter={tableItem.columnCenter}
          columnRight={tableItem.columnRight}
          columnFarRight={
            headerFarRight && headerFarRight.trim()
              ? tableItem.columnFarRight
              : null
          }
          iconCenterName={tableItem.iconCenterName}
          iconRightName={tableItem.iconRightName}
          iconFarRightName={
            headerFarRight && headerFarRight.trim()
              ? tableItem.iconFarRightName
              : null
          }
          shouldRenderFarRight={headerFarRight && headerFarRight.trim()}
          columnLeftBgColor={columnLeftBgColor}
          columnCenterBgColor={columnCenterBgColor}
          columnRightBgColor={columnRightBgColor}
          columnFarRightBgColor={columnFarRightBgColor}
        />
      ))}
    </TableWrapper>
  );
};

TableSection.propTypes = {
  headerLeft: PropTypes.string.isRequired,
  headerCenter: PropTypes.string.isRequired,
  headerRight: PropTypes.string.isRequired,
  headerFarRight: PropTypes.string,
  headLeftBgColor: PropTypes.string,
  headCenterBgColor: PropTypes.string,
  headRightBgColor: PropTypes.string,
  headFarRightBgColor: PropTypes.string,
  cellTextColor: PropTypes.string,
  columnLeftBgColor: PropTypes.string,
  columnCenterBgColor: PropTypes.string,
  columnRightBgColor: PropTypes.string,
  columnFarRightBgColor: PropTypes.string,
  tableItems: PropTypes.array,
};

export default TableSection;
