import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  padding: ${({ theme }) => theme.layouts.navPadding};
  margin-top: 6rem;
`;
const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(0.6rem, 1vw, 1rem);
`;

const Title = styled.h2`
  ${({ theme }) => theme.mixins.textH3};
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textP};
  color: ${({ theme }) => theme.colors.text};
`;

const PageHeader = ({ title, subtitle }) => (
  <Container>
    <Content>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Content>
  </Container>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default PageHeader;
