const content = {
  seo: {
    title: 'TigerDC | Team',
    description:
      'Our management team possesses extensive expertise in finance, data center engineering, and project management.',
    canonical: '/team',
  },
  headlineSection: {
    sectionTitle: 'The Team',
    sectionSubtitle: 'Our industry leaders behind TigerDC',
  },
  teamSection: {
    cards: [
      {
        profileName: 'Jerry Tang',
        profilePosition: 'CEO',
        profileImage:
          'https://storage.googleapis.com/tiger-dc-web/images/profileImages/jerry/s.webp',
        profileDescription:
          '20 years in commercial real estate, senior executive at a top 20 global bank, managed $40B+ in transactions.',
      },
      {
        profileName: 'Matt Feast',
        profilePosition: 'CFO',
        profileImage:
          'https://storage.googleapis.com/tiger-dc-web/images/profileImages/matt/s.webp',
        profileDescription:
          '20 years in commercial real estate, with over $20 billion originated, sold, or securitized.',
      },
      {
        profileName: 'Joshua Crass',
        profilePosition: 'Project Manager',
        profileImage:
          'https://storage.googleapis.com/tiger-dc-web/images/profileImages/joshua/s.webp',
        profileDescription:
          'Data center leader with over 20 years of experience, including roles in construction and operations at Google and Meta.',
      },
      {
        profileName: 'John Jiang',
        profileImage:
          'https://storage.googleapis.com/tiger-dc-web/images/profileImages/john/s.webp',
        profilePosition: 'Technology',
        profileDescription: 'Cloud Manager at Google.',
      },
      {
        profileName: 'Jeff Fini',
        profilePosition: 'MEP',
        profileImage:
          'https://storage.googleapis.com/tiger-dc-web/images/profileImages/jeff/s.webp',
        profileDescription:
          'Over 30 years in engineering design, development, and project execution,  more than $20 billion.',
      },
      {
        profileName: 'Bill Launikitis',
        profilePosition: 'MEP',
        profileImage:
          'https://storage.googleapis.com/tiger-dc-web/images/profileImages/bill/s.webp',
        profileDescription:
          'Over 25 years in data center design, construction, and commissioning, totaling 2M sq ft.',
      },
      {
        profileName: 'Mehmet Akcin',
        profilePosition: 'Advisor',
        profileImage: '',
        profileDescription: 'Former Microsoft telecom executive.',
      },
    ],
  },
};
export default content;
