import styled from '@emotion/styled';
import Header from 'organisms/Header';
import Footer from 'organisms/Footer';
import SectionHeader from 'molecules/SectionHeader';
import content from 'data/partners';
import LogoFeature from 'molecules/LogoFeature';

import SEO from 'layouts/SEO';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`;
const PageTop = styled.section`
  background-color: ${({ theme }) => theme.colors.black};
  margin-top: 5rem;
`;
const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  height: 100%;
  flex: 1;
`;

const LogoFeatureWrapper = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }
`;

const Partners = () => {
  const { pageTitle, pageSubtitle } = content.headlineSection;
  const { logos } = content.logoSection;
  const { title, description, canonical } = content.seo;

  return (
    <Page>
      <SEO title={title} description={description} canonical={canonical} />
      <Header />
      <PageTop>
        <SectionHeader
          headline={pageTitle}
          caption={pageSubtitle}
          headlineColor="white"
        />
      </PageTop>
      <Container>
        <LogoFeatureWrapper>
          {logos.map((logoContainer, index) => (
            <LogoFeature
              key={index}
              bgColor={logoContainer.bgColor}
              titleColor={logoContainer.titleColor}
              containerTitle={logoContainer.containerTitle}
              logos={logoContainer.logos}
            />
          ))}
        </LogoFeatureWrapper>
      </Container>
      <Footer />
    </Page>
  );
};

export default Partners;
