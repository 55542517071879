import styled from '@emotion/styled';
import content from 'data/home';
import Button from 'atoms/Button';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  min-height: 100dvh;

  background-image: linear-gradient(
      to bottom,
      ${({ theme }) => `${theme.colors.darkBlue}A6`},
      ${({ theme }) => `${theme.colors.darkBlue}D9`}
    ),
    ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};
  display: flex;
  flex-direction: column;
  gap: clamp(2rem, 3vw, 4rem);
  margin-top: 4rem; //find better solution later
`;

const TitleWrapper = styled.div`
  ${({ theme }) => theme.mixins.textH1};
  font-size: clamp(5.3rem, 8vw, 12.8rem);
`;

const TitleLine = styled.h1`
  height: 1em;
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.mixins.textSubtitle};
  color: ${({ theme }) => theme.colors.white};

  width: 25rem;

  ${({ theme }) => theme.mediaQueries.md} {
    width: clamp(25rem, 40%, 35rem);
  }
`;

const ButtonWrapper = styled.div`
  width: max-content;
  margin-top: clamp(4rem, 8vw, 8rem);

  ${({ theme }) => theme.mediaQueries.md} {
    align-self: center;
  }
`;

const getWordColor = (word, index) => {
  if (word === '.') return 'greenAccent';
  return index === 1 ? 'white' : 'greenAccent';
};

const Word = styled.span`
  color: ${({ theme, color }) => theme.colors[color]};
  margin-right: ${({ isLast }) => (isLast ? '0' : '0.25rem')};
`;

const HomeHero = () => {
  const { sectionTitles, sectionSubtitle, backgroundImage } =
    content.heroSection;

  // Group words into lines (word + optional period)
  const lines = sectionTitles.reduce((acc, word, i) => {
    if (word === '.' && i > 0) {
      acc[acc.length - 1].push(word);
    } else if (word !== '.') {
      acc.push([word]);
    }
    return acc;
  }, []);

  return (
    <Container backgroundImage={backgroundImage}>
      <Content>
        <TitleWrapper>
          {lines.map((line, lineIndex) => (
            <TitleLine key={lineIndex}>
              {line.map((word, wordIndex) => (
                <Word
                  key={`${lineIndex}-${wordIndex}`}
                  color={getWordColor(word, lineIndex)}
                  isLast={wordIndex === line.length - 1}
                >
                  {word}
                </Word>
              ))}
            </TitleLine>
          ))}
        </TitleWrapper>
        <Subtitle>{sectionSubtitle}</Subtitle>
        <ButtonWrapper>
          <Button
            url="#featured"
            label="See the Future"
            color="white"
            iconName="arrowDown"
          />
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default HomeHero;
