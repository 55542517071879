// src/components/layouts/SEO.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

import PropTypes from 'prop-types';

const SEO = ({
  title = 'Tiger DC | Tiger Data Center ',
  description = 'TigerDC designs and operates purpose-built AI data centers optimized for high-density. With advanced liquid cooling solutions like Direct-to-Chip and Rear Door Heat Exchangers, we efficiently manage racks exceeding 100 kW, delivering the performance and reliability needed for AI workloads.',
  canonical,
  ogImage = 'https://storage.googleapis.com/tiger-dc-web/assets/images/BuildingEntrance/xl.webp',
  ogType = 'website',
  noindex = false,
  keywords = 'TigerDC, AIDC, AI, DC, Data Center, Tiger , Liquid Cooling, Tomorrows Data Center Today, cooling solutions, Direct-to-Chip, Rear Door Heat Exchangers',
}) => {
  const siteUrl = 'https://tigerdc.com';
  const canonicalUrl = canonical ? `${siteUrl}${canonical}` : siteUrl;
  const ogImageUrl = `${siteUrl}${ogImage}`;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />
      {noindex && <meta name="robots" content="noindex" />}

      {/* OpenGraph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:site_name" content="TigerDC" />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  ogImage: PropTypes.string,
  ogType: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterCreator: PropTypes.string,
  noindex: PropTypes.bool,
  keywords: PropTypes.string,
};

export default SEO;
