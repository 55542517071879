import styled from '@emotion/styled';
import Image from 'atoms/Image';
import Features from 'molecules/Features';

const Container = styled.section`
  ${({ theme }) => theme.layouts.pageContainer};
  position: relative;
`;

const Content = styled.div`
  ${({ theme }) => theme.layouts.pageContent};

  text-align: center;

  ${({ theme }) => theme.mixins.flexColCenter};
  gap: clamp(2rem, 3vw, 4rem);
`;

const TItle = styled.h3`
  ${({ theme }) => theme.mixins.textH3};

  color: ${({ theme }) => theme.colors.white};
  width: clamp(22rem, 60%, 40rem);
`;

const Bg = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  z-index: -1;
`;

const BgOverlay = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  z-index: 1;

  background: linear-gradient(
    to bottom,
    ${({ theme }) => `${theme.colors.darkBlue}A6`},
    ${({ theme }) => `${theme.colors.darkBlue}D9`}
  );
`;

const PageFeatureBanner = ({ content }) => {
  const { title, features, bgImage, bgImageLabel } = content;
  return (
    <Container>
      <Content>
        <TItle>{title}</TItle>
        <Features features={features} />
      </Content>
      <Bg>
        <BgOverlay aria-label="background image overlay element"></BgOverlay>
        <Image src={bgImage} label={bgImageLabel} fit="cover" />
      </Bg>
    </Container>
  );
};
export default PageFeatureBanner;
